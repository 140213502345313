import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Summon launch exclusive partnership with ONDA"
      description="Summon announce exclusive partnership with ONDA, providing financial administration services for busy Australian individuals and families "
      location={location}
    />

    <PageHeader
      title="Summon launch exclusive partnership with ONDA"
      text="Summon announce exclusive partnership with ONDA, providing financial administration services for busy Australian individuals and families "
    />

    <SectionPageMargin>

      <Heading2>Special exclusive offer for Summon customers</Heading2>

      <Paragraph1>
        Summon and ONDA are proud to announce our partnership with an exclusive offer to Summon customers (see below).
      </Paragraph1>

      <Paragraph1>
        Summon customers choose our concierge service to save them time, and maximise the value of their prestige car. ONDA provide a similar concierge service for the financial administration in your life.
      </Paragraph1>

      <Paragraph1>
        ONDA are an independent, Australian based provider of financial administration services for individuals and families, driven by world class technology and security.
      </Paragraph1>

      <Paragraph1>
        <a href="https://ondagroup.com.au/">ONDA</a> is revolutionising the way that you value life’s most valuable asset – time – by outsourcing the management and review of your finances. Every client has a dedicated support team that receives, pays, stores, manages and reconciles all bills and outgoings.
      </Paragraph1>

      <Paragraph1>
        In a world where we outsource our cleaning, cooking, child minding, fitness, style, coaching, and sale of your car…why not outsource the management of your day-to-day life, family or business administration to ONDA to achieve better outcomes?
      </Paragraph1>

      <Paragraph1>
        As part of our partnership, enquire with ONDA and quote “Summon” to receive a 25% reduction in all new ONDA client fees for this financial year.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
